/*message modal */
var bmodal = $('#contactModal');
var alertHolder = document.getElementById("forAlerts");

bmodal.on('show.bs.modal', function(e) {

  const el = e.relatedTarget;
  const recipient = $(el).data("recipient");
  const id = $(el).data("id");

  const form = $(this).find("#cntfrm");

  $('#advertId').val(id);
  $('#contactLabel span').html(recipient);

  form.attr('data-id', id);

  form.get(0).reset();
});

bmodal.on('hidden.bs.modal', function(e) {
  if (form.classList.contains('was-validated')) {
    form.classList.remove('was-validated')
  }
  $("#msgresult").removeClass().html('');
});

var form = document.querySelector('.needs-validation');

form.addEventListener('submit', async(e) => {

  e.preventDefault();
  e.stopPropagation();

  if (form.checkValidity() === true) {

    const eml = $('#clientEmail').val();
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    re.test(String(eml).toLowerCase());
    
    const data = new FormData(e.target);
    const adId = e.target.getAttribute('data-id');
    
    const apiUrl = '/api/0/contact/' + adId;

    const alert = '<div class="alert alert-dismissible fade" role="alert">' + 
                  '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' + 
                  '<span aria-hidden="true">&times;</span></button></div>';
  
    const useCaptcha = (typeof window.__useCaptcha !== 'undefined') ? window.__useCaptcha : null;
    const recaptchaSiteKey = (typeof window.__recaptchaSiteKey !== 'undefined') ? window.__recaptchaSiteKey : null;

    alertHolder.innerHTML = alert;
    
    var alertText;
  
    try {

      if (useCaptcha) {

        if (!recaptchaSiteKey) {
          throw new Error('reCAPTCHA API key is missing.');
        }

        let token = await validateCaptcha(recaptchaSiteKey);

        if (!token) {
          throw new Error('Something is wrong. Please try again.');
        }

        data.set('g-recaptcha-response', token);
      }

      const response = await fetch(apiUrl, {
        method: 'POST',
        body: new URLSearchParams(data).toString(),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      if (!response.ok) {
        const error = await response.text();
        throw new Error(JSON.parse(error).message);
      }

      const json = await response.json();

      if (json.sent === 1) {

        // success
        bmodal.modal("hide");
        alertHolder.firstElementChild.classList.add('show', 'alert-success');
        alertText = document.createTextNode('Your message has been sent. Thank you.');
        $('.alert').append(alertText);

      } else if (form.checkValidity() === true && json.sent === 0) {
        // server validation error
        throw new Error('Something is wrong. Please try again.');
      }
      
    } catch(err) { 
      console.log(err);
      $("#msgresult").addClass('text-danger').html(err);
    }
  }

  form.classList.add('was-validated');

}, false);

/*message modal end*/

// Gallery Lightbox with lazy loaded images
var gallery = function () {

  var counter;
  var countImg = document.querySelector('#gallery-sheet').children.length; // number of images in the gallery
  var lightbox = document.querySelector('.lightbox'); // hires image wrapper
  var currentSlide;

  var hideBtn = function () {
    if (counter == countImg - 1) {
      $('.btn-next').addClass('inactive').prop('disabled', true);
    }
    else if (counter == 0) {
      $('.btn-prev').addClass('inactive').prop('disabled', true);
    }
    else {
      $('.btn-next, .btn-prev').removeClass('inactive').prop('disabled', false);
    }
  };


  $('.thumb').on('click', function (event) {

    counter = Number(this.getAttribute("data-index"));

    // console.log('opening counter: ' + counter);

    var backdrop = '<div class="modal-backdrop fade"></div>'; // modal backdrop from bootstrap
    var prevEl = this.previousElementSibling;
    var nextEl = this.nextElementSibling;

    var items = [this]; // anticipating which images need to be hires when thumb is clicked

    if (prevEl !== null) {
      items.push(prevEl)
    }

    if (nextEl !== null) {
      items.push(nextEl)
    }


    items.forEach(function (item, index) {
      item.src = item.getAttribute("data-src");
    });

    if (!document.body.contains(document.querySelector('.modal-backdrop'))) {

      var largeImg = this.cloneNode('true'); // create the element holding hires image
      largeImg.classList.add('hres', 'show-up');
      lightbox.append(largeImg);

      // Elements holding adjacent images
      if (nextEl !== null) {
        var nextNextEl = nextEl.cloneNode('true');
        nextNextEl.classList.add('hres');
        largeImg.insertAdjacentElement('afterend', nextNextEl);
      }

      if (prevEl !== null) {
        var prevPrevEl = prevEl.cloneNode('true');
        prevPrevEl.classList.add('hres');
        largeImg.insertAdjacentElement('beforebegin', prevPrevEl);
      }

      $('body').addClass('modal-open');
      $('.lightbox').append(backdrop);
      $('.modal-backdrop').addClass('show');
      lightbox.style.display = 'block';
      $('.close--gallery').addClass('show');
    }


    $('.modal-backdrop, .close--gallery').on('click', function () {
      $('body').removeClass('modal-open');
      $('.close--gallery').removeClass('show');
      $('.modal-backdrop, .hres').remove();
      lightbox.style.display = 'none';
      $('.btn-next, .btn-prev').removeClass('inactive').prop('disabled', false);

      // console.log('lightbox closed counter: ' + counter);
    });

    hideBtn();

  });

  var next = function () {

    currentSlide = document.querySelector('.show-up');

    // console.log('starting counter ' + counter);

    var indexForward = counter + 2;
    var nextSlide = document.querySelector('.show-up').nextElementSibling;
    currentSlide.classList.remove('show-up');
    nextSlide.classList.add('show-up');

    // console.log('indexForward is: ' + indexForward);

    if (indexForward !== countImg) {

      var forwardSlide = document.querySelector('[data-index =' + CSS.escape(indexForward) + ']');

      forwardSlide.src = forwardSlide.getAttribute("data-src");
      var forwardImg = forwardSlide.cloneNode('true');
      forwardImg.classList.add('hres');
      nextSlide.insertAdjacentElement('afterend', forwardImg);
    }


    counter++;

    hideBtn();

    // console.log('increasing counter ' + counter);

  };

  var prev = function () {

    currentSlide = document.querySelector('.show-up');

    // console.log('starting counter ' + counter);

    var indexBackward = counter - 2;
    var prevSlide = document.querySelector('.show-up').previousElementSibling;
    currentSlide.classList.remove('show-up');
    prevSlide.classList.add('show-up');

    // console.log('indexBackward is: ' + indexBackward);

    if (indexBackward !== -1) {

      var backwardSlide = document.querySelector('[data-index =' + CSS.escape(indexBackward) + ']');

      backwardSlide.src = backwardSlide.getAttribute("data-src");
      var backwardImg = backwardSlide.cloneNode('true');
      backwardImg.classList.add('hres');
      prevSlide.insertAdjacentElement('beforebegin', backwardImg);
    }


    counter--;

    hideBtn();

    // console.log('decreasing counter ' + counter);

  };

  $('.btn-next').on('click', next);
  $('.btn-prev').on('click', prev);
};


window.onload = function () {

  var gal = $("#gallery-sheet");
  var firstImg;
  if (gal.length>0) {
    gallery();
   firstImg = document.getElementById("gallery-sheet").firstElementChild;
   firstImg.src = firstImg.getAttribute("data-src");
  }
};

function validateCaptcha(token) {
  return new Promise((res, rej) => {

    if (typeof grecaptcha === 'undefined') {
      grecaptcha = {};
    }

    grecaptcha.ready = function(cb) {
      if (typeof grecaptcha === 'undefined') {
        const c = '___grecaptcha_cfg';
        window[c] = window[c] || {};
        (window[c]['fns'] = window[c]['fns']||[]).push(cb);
      } else {
        cb();
      }
    }

    grecaptcha.ready(() => {

      try {

        grecaptcha.execute(token, {action: 'loveme_contact_form'})
          .then((token) => res(token));

      } catch (err) {
        rej(err && err.message || 'Captcha request error.');
      }
    })
  })
};